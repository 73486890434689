@import url('https://fonts.googleapis.com/css2?family=Nunito:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

body {
    font-family: 'Nunito', sans-serif;
    overflow-x: hidden;
}

.font-alt {
    font-family: 'Noto Sans JP', sans-serif;
}
